import { configureStore } from "@reduxjs/toolkit";
import { allApi, publicApi } from "./mutation/AllApi";
import userReducer from "./mutation/userSlice";
const store = configureStore({
  reducer: {
    user: userReducer,
    [allApi.reducerPath]: allApi.reducer,
    [publicApi.reducerPath]: publicApi.reducer,
  },

  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(allApi.middleware, publicApi.middleware);
  },
  //   devTools: process.env.NODE_ENV !== "production",
});

export default store;

export {
  useCreateUserMutation,
  useCreateEnquiryMutation,
  useGetOtpMutation,
  useResetPasswordMutation,
  useFetchProPmtServicesQuery,
  useFetchProPmtCategoryQuery,
  useCreateOrderMutation,
  useGetPmtServiceDetailsQuery,
  useFetchPmtCoursesQuery,
  useGetMycourseDetailsQuery,
  useUpdateUserMutation,
} from "./mutation/AllApi";
export {
  useFetchProPmtSliderQuery
} from "./mutation/AllApi";
